import { IMember } from "./MemberInterface";
import axios from "axios";
import Agent from "../Agent";
import { IDependent } from "../Dependent/DependentInterface";
import Dependent from "../Dependent";
import Payment from "../Payment";
import Plan from "../Plan";
import {
  E123ApiURL,
  memberCreateErrorHandlerZapierWebhookURL,
  d2cWebhook,
  middlewareV1Hook,
  middlewareV1_5Hook,
  SVGUplines,
  LETTER_ID_DENTAL_DENTALandVISION,
  LETTER_ID_VISION_ONLY_OR,
  LETTER_ID_VISION_ONLY_DEFAULT,
  LETTER_ID_ENROLLMENT_CONFIRMATION,
} from "../../utils";
import AgentLicense from "../AgentLicense";
import { handleAxiosError } from "../../axiosHelper";
import ErrorLog, { LogLevel } from "../ErrorLog";
import { validateCCURL } from "../../utils";
import qs from 'qs';

export interface ICreateMemberResponse {
  transaction: any;
  state: any;
  message: any;
}
let validationRetryCount = 0;

export async function validateTransaction(member: Member) {
  try {
    if (member.payment?.paymentType === 'CC') {
      // Credit Card Validation
      const url = `${validateCCURL}`;
      // NOTE we (incorrectly?) refer to cvv as ccv throughout the app. So it is cvv here but other places ccv...
      const postData = {
        ccnumber: member.payment?.ccNumber,
        ccexp: `${member.payment?.ccExpMonth}${member.payment?.ccExpYear}`,
        cvv: member.payment?.ccSecurityCode,
        agentid: member.agent?.id,
        sessionid: window.location.href.split('/').pop(),
      };
      const response = await axios.post(url, postData, {
        headers: { 'Content-Type': 'application/json' },
      });

      const responseParams = qs.parse(response.data);
      const responseCode = responseParams.response_code;

      switch (responseCode) {
        case '100':
          // Transaction Approved
          validationRetryCount = 0; // Reset retry count on success
          return { success: true };
        case '202':
        case '220':
        case '224':
          // Insufficient funds, Incorrect card number/expiry
          validationRetryCount++;
          if (validationRetryCount >= 3) {
            return {
              success: false,
              showModal: true,
              errorCode: '504',
            };
          }
          return {
            success: false,
            showModal: true,
            errorCode: responseCode,
          };
        default:
          // Handle other cases
          return { success: true };
      }
    } else {
      // Skip validation for ACH
      return { success: true };
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error('Axios error:', error.message);
      if (error.response) {
        console.error('Response data:', error.response.data);
        console.error('Response status:', error.response.status);
        console.error('Response headers:', error.response.headers);
      } else if (error.request) {
        console.error('Request data:', error.request);
      }
    } else {
      console.error('Unexpected error:', error);
    }
    // Handle gateway timeout (504) or other network-related errors
    return { success: true };
  }
}

export default class Member implements IMember {
  corpID?: number;
  agent?: Agent;
  id?: string;
  createdDate?: Date;
  firstName?: string;
  middleName?: string;
  lastName?: string;
  dateOfBirth?: string;
  gender?: string;
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  zipCode?: string;
  otherAddress1?: string;
  otherAddress2?: string;
  otherCity?: string;
  otherState?: string;
  otherZipCode?: string;
  email?: string;
  phone1?: string;
  phone2?: string;
  phone3?: string;
  dependents?: IDependent[];
  payment?: Payment;
  paymentProcess?: string;
  billingDate?: string;
  effectiveDate?: string;

  constructor(
    corpID?: number,
    agent?: Agent,
    id?: string,
    createdDate?: Date,
    firstName?: string,
    middleName?: string,
    lastName?: string,
    dateOfBirth = "01/01/1977",
    gender?: string,
    address1?: string,
    address2?: string,
    city?: string,
    state?: string,
    zipCode?: string,
    otherAddress1?: string,
    otherAddress2?: string,
    otherCity?: string,
    otherState?: string,
    otherZipCode?: string,
    email?: string,
    phone1?: string,
    phone2?: string,
    phone3?: string,
    dependents?: Dependent[],
    payment?: Payment,
    paymentProcess?: string,
    billingDate?: string,
    effectiveDate?: string
  ) {
    this.corpID = corpID;
    this.agent = agent;
    this.id = id;
    this.createdDate = createdDate;
    this.firstName = firstName;
    this.middleName = middleName;
    this.lastName = lastName;
    this.dateOfBirth = dateOfBirth;
    this.gender = gender;
    this.address1 = address1;
    this.address2 = address2;
    this.city = city;
    this.state = state;
    this.zipCode = zipCode;
    this.otherAddress1 = otherAddress1;
    this.otherAddress2 = otherAddress2;
    this.otherCity = otherCity;
    this.otherState = otherState;
    this.otherZipCode = otherZipCode;
    this.email = email;
    this.phone1 = phone1;
    this.phone2 = phone2;
    this.phone3 = phone3;
    this.dependents = dependents;
    this.payment = payment;
    this.paymentProcess = paymentProcess;
    this.billingDate = billingDate;
    this.effectiveDate = effectiveDate;
  }

  /**
   * @name createMember
   * @description this function uses the instance of member to save it on the database
   * */
  createMember = async (
    plans: Plan[],
    associationPlan: Plan,
    agentID: number,
    zipCodeState: string,
    availableStatesForAgent: AgentLicense[],
    wantsDental: boolean,
    wantsVision: boolean
  ) => {
    let validAgent = false;
    const today = new Date();
    let letterId = LETTER_ID_DENTAL_DENTALandVISION;

    // Check that availableStatesForAgent includes zipCodeState && not expired
    for (let i = 0; i < availableStatesForAgent.length; i++) {
      if (availableStatesForAgent[i].state === zipCodeState) {
        if (
          new Date(availableStatesForAgent[i].expiration_date || "") > today ||
          availableStatesForAgent[i].expiration_date === ""
        ) {
          // Non expiring license
          validAgent = true;
        }
      }
    }

    const dependents = this.dependents?.map((dependent) => {
      return {
        FIRSTNAME: dependent.firstName,
        MIDDLENAME: dependent.middleName,
        LASTNAME: dependent.lastName,
        RELATIONSHIP: dependent.relationship,
        GENDER: dependent.gender,
        DOB: dependent.dateOfBirth,
      };
    });
    const products = [...plans, associationPlan].map((plan) => {
      if (!plan?.id) return null;

      //default productID
      let planId = plan.id;

      // SVG plans are different. 
      // This mapping will replace the appropriate planId with the SVG planId
        if (SVGUplines.includes(this.agent?.parentAgentId!)) {
        switch (planId) {
          case 42249:  // elite 1500
          planId = 44855;
          break;
        case 42250:  // elite 3000
          planId = 44856;
          break;
        case 42251:  // elite 5000
          planId = 44857;
          break;
        case 38796:  // value
          planId = 44861;
          break;
        case 38398:  // essentials
          planId = 44864;
          break;
        case 38399:  // complete
          planId = 44865;
          break;
        case 38593:  // VSP Preferred Plan by NCD
          planId = 44847;
          break;
        case 39651:  // Vision OR
          planId = 45051;
          break;
          case 38449:  // low dental association
          planId = 44852;
          break;
        case 38450:  // mid/high dental association
          planId = 44851;
          break;
          case 38928:  // low combo association
          planId = 44853;
          break;
        case 38941:  // mid/high combo association
          planId = 44854;
          break;
        case 38923:  // vsp only association
          planId = 44850;
          break;
        }
      }

      return {
        PDID: planId,
        PERIODID: plan.periodID,
        BENEFITID: plan.benefitID,
        ENROLLERID: validAgent ? null : agentID,
        dtBilling: this.billingDate,
        dtEffective: this.effectiveDate,
      };
    });

    // validity check: associationPlan presence && dateOfBith is valid
    if (!associationPlan || this.dateOfBirth === "NaN/NaN/NaN") {
      return {
        transaction: false,
        state: false,
        message: "",
      };
    }

    if (!wantsDental && wantsVision) {
      letterId = 
        zipCodeState === "OR"
        ? LETTER_ID_VISION_ONLY_OR
        : LETTER_ID_VISION_ONLY_DEFAULT
    }

    if (SVGUplines.includes(this.agent?.parentAgentId!)) {
      letterId = `${letterId},${LETTER_ID_ENROLLMENT_CONFIRMATION}`;
    }

    const HEADERS = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `${process.env.REACT_APP_API123METLIFEAUTH}`,
    };

    const MIDDLEWAREHEADERS = {
      "Content-Type": "application/json",
      Authorization: `${process.env.REACT_APP_API123METLIFEAUTH}`,
    };

    // set up various payloads.
    // the saved sales bucket is used when !validAgent
    const DEFAULT_PAYLOAD = {
      CORPID: 1376,
      AGENT: validAgent ? agentID : 622635,
      FIRSTNAME: this.firstName,
      MIDDLENAME: this.middleName,
      LASTNAME: this.lastName,
      DOB: this.dateOfBirth,
      GENDER: this.gender,
      ADDRESS1: this.address1,
      ADDRESS2: this.address2,
      CITY: this.city,
      STATE: this.state,
      ZIPCODE: this.zipCode,
      EMAIL: this.email,
      PHONE1: this.phone1,
      DEPENDENTS: dependents,
      PAYMENT: this.payment,
      PRODUCTS: products,
      PAYMENTPROCESS: this.paymentProcess,
      LETTER_TEMPLATE_IDS: letterId,
      TrackFlag: "Application",
    };

    // does not include payment
    const MIDDLEWARE_PAYLOAD = {
      CORPID: 1376,
      AGENT: validAgent ? agentID : 622635,
      FIRSTNAME: this.firstName,
      MIDDLENAME: this.middleName,
      LASTNAME: this.lastName,
      DOB: this.dateOfBirth,
      GENDER: this.gender,
      ADDRESS1: this.address1,
      ADDRESS2: this.address2,
      CITY: this.city,
      STATE: this.state,
      ZIPCODE: this.zipCode,
      EMAIL: this.email,
      PHONE1: this.phone1,
      DEPENDENTS: dependents,
      //PAYMENT: this.payment,
      PRODUCTS: products,
      PAYMENTPROCESS: this.paymentProcess,
      LETTER_TEMPLATE_IDS: letterId,
      TrackFlag: "Application",
    };

    // this appears to be same as default payload
    const MIDDLEWARE_V1_5_PAYLOAD = {
      CORPID: 1376,
      AGENT: validAgent ? agentID : 622635,
      FIRSTNAME: this.firstName,
      MIDDLENAME: this.middleName,
      LASTNAME: this.lastName,
      DOB: this.dateOfBirth,
      GENDER: this.gender,
      ADDRESS1: this.address1,
      ADDRESS2: this.address2,
      CITY: this.city,
      STATE: this.state,
      ZIPCODE: this.zipCode,
      EMAIL: this.email,
      PHONE1: this.phone1,
      DEPENDENTS: dependents,
      PAYMENT: this.payment,
      PRODUCTS: products,
      PAYMENTPROCESS: this.paymentProcess,
      LETTER_TEMPLATE_IDS: letterId,
      TrackFlag: "Application",
    };

    const splitLetterId = letterId.split(",");
    return Promise.all([
      axios.post(
        `${process.env.REACT_APP_API123METLIFE}`,
        DEFAULT_PAYLOAD,
        { headers: HEADERS }
      ),
      axios.post(`${process.env.REACT_APP_MIDDLEWAREV1}`, MIDDLEWARE_PAYLOAD, { headers: HEADERS }),
      axios.post(`${process.env.REACT_APP_MIDDLEWAREV1_5}`, MIDDLEWARE_V1_5_PAYLOAD, {
        headers: MIDDLEWAREHEADERS,
      }),
    ])
      .then(([apiResponse, middlewareResponse]) => {
        this.id = apiResponse.data.MEMBER?.ID;

        if (!apiResponse.data) {
          return {
            transaction: false,
            state: false,
            message: "",
          };
        }

        if (!apiResponse.data.SUCCESS) {
          axios.post(
            `${process.env.REACT_APP_MEMBERCREATEERRORHANDLER}`,
            JSON.stringify({
              payload: {
                CORPID: 1376,
                AGENT: validAgent ? agentID : 622635,
                FIRSTNAME: this.firstName,
                MIDDLENAME: this.middleName,
                LASTNAME: this.lastName,
                DOB: this.dateOfBirth,
                GENDER: this.gender,
                ADDRESS1: this.address1,
                ADDRESS2: this.address2,
                CITY: this.city,
                STATE: this.state,
                ZIPCODE: this.zipCode,
                EMAIL: this.email,
                PHONE1: this.phone1,
                DEPENDENTS: dependents,
                PRODUCTS: products,
                PAYMENTPROCESS: this.paymentProcess,
                LETTER_TEMPLATE_IDS: parseInt(splitLetterId[0], 10),
              },
              response: { data: apiResponse.data },
            })
          );
        }

        if (apiResponse.data.SUCCESS && agentID === 660555) {
          axios
            .post(`${process.env.REACT_APP_D2CWEBHOOK}`, {
              CORPID: 1376,
              AGENT: validAgent ? agentID : 622635,
              FIRSTNAME: this.firstName,
              MIDDLENAME: this.middleName,
              LASTNAME: this.lastName,
              DOB: this.dateOfBirth,
              GENDER: this.gender,
              ADDRESS1: this.address1,
              ADDRESS2: this.address2,
              CITY: this.city,
              STATE: this.state,
              ZIPCODE: this.zipCode,
              EMAIL: this.email,
              PHONE1: this.phone1,
              DEPENDENTS: dependents,
              PAYMENT: this.payment,
              PRODUCTS: products,
              PAYMENTPROCESS: this.paymentProcess,
              LETTER_TEMPLATE_IDS: letterId,
              TrackFlag: "Application",
            })
            .catch((error) => {
              handleAxiosError(error)
            });
        }

        return {
          transaction:
            (apiResponse.data.TRANSACTION &&
              apiResponse.data.TRANSACTION.SUCCESS) ||
            false,
          state: apiResponse.data.SUCCESS,
          message:
            (apiResponse.data.TRANSACTION &&
              apiResponse.data.TRANSACTION.RESPONSE) ||
            "",
        };
      })
      .catch((error) => {
        try {
          handleAxiosError(error)
          axios.post(
            `${process.env.REACT_APP_MEMBERCREATEERRORHANDLER}`,
            JSON.stringify({
              payload: {
                CORPID: 1376,
                AGENT: validAgent ? agentID : 622635,
                FIRSTNAME: this.firstName,
                MIDDLENAME: this.middleName,
                LASTNAME: this.lastName,
                DOB: this.dateOfBirth,
                GENDER: this.gender,
                ADDRESS1: this.address1,
                ADDRESS2: this.address2,
                CITY: this.city,
                STATE: this.state,
                ZIPCODE: this.zipCode,
                EMAIL: this.email,
                PHONE1: this.phone1,
                DEPENDENTS: dependents,
                PRODUCTS: products,
                PAYMENTPROCESS: this.paymentProcess,
                LETTER_TEMPLATE_IDS: parseInt(splitLetterId[0], 10),
              },
              response: { error },
            })
          ).catch((error)=>{
            const msg = 'Could not report error - axios catch'
            ErrorLog.getInstance().logError(error, msg, LogLevel.error)
          });
        } catch {
          const msg = 'Could not report error - try catch'
          ErrorLog.getInstance().logError(new Error(msg), msg, LogLevel.error)
        }
        return {
          transaction: false,
          state: false,
          message: error.message,
        };
      });
  };

  /**
   * @name updateMember
   * @description this function uses the instance of member to update it on the database
   * */
  updateMember = async (
    plans: Plan[],
    associationPlan: Plan,
    memberInstance: Member,
    agentID: number,
    zipCodeState: string,
    availableStatesForAgent: AgentLicense[]
  ) => {
    let validAgent = false;

    for (let i = 0; i < availableStatesForAgent.length; i++) {
      if (availableStatesForAgent[i].state === zipCodeState) {
        validAgent = true;
      }
    }

    const products = [...plans, associationPlan].map((plan) => {
      if (!plan?.id) return null;
      return {
        PDID: plan.id,
        PERIODID: plan.periodID,
        BENEFITID: plan.benefitID,
        ENROLLERID: validAgent ? null : agentID,
        dtBilling: this.billingDate,
        dtEffective: this.effectiveDate,
      };
    });

    return axios
      .post(
        `${process.env.REACT_APP_API123METLIFE}`,
        {
          member: memberInstance.id,
          CORPID: 1376,
          AGENT: validAgent ? agentID : 622635,
          FIRSTNAME: memberInstance.firstName,
          LASTNAME: memberInstance.lastName,
          PAYMENT: memberInstance.payment,
          PRODUCTS: products,
          PAYMENTPROCESS: memberInstance.paymentProcess,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `${process.env.REACT_APP_API123METLIFEAUTH}`,
          },
        }
      )
      .then((response) => response.data)
      .then((data) => {
        this.id = data.MEMBER?.ID;
        if (!data)
          return {
            transaction: false,
            state: false,
            message: "",
          };

        return {
          transaction: (data.TRANSACTION && data.TRANSACTION.SUCCESS) || false,
          state: data.SUCCESS,
          message: (data.TRANSACTION && data.TRANSACTION.RESPONSE) || "",
        };
      });
  };

  /**
   * @name clearMember
   * @description Clears all properties of the Member instance. Also resets the validationRetryCount
   */
  clearMember = () => {
    this.corpID = undefined;
    this.agent = undefined;
    this.id = undefined;
    this.createdDate = undefined;
    this.firstName = undefined;
    this.middleName = undefined;
    this.lastName = undefined;
    this.dateOfBirth = "01/01/1977";
    this.gender = undefined;
    this.address1 = undefined;
    this.address2 = undefined;
    this.city = undefined;
    this.state = undefined;
    this.zipCode = undefined;
    this.otherAddress1 = undefined;
    this.otherAddress2 = undefined;
    this.otherCity = undefined;
    this.otherState = undefined;
    this.otherZipCode = undefined;
    this.email = undefined;
    this.phone1 = undefined;
    this.phone2 = undefined;
    this.phone3 = undefined;
    this.dependents = undefined;
    this.payment = undefined;
    this.paymentProcess = undefined;
    this.billingDate = undefined;
    this.effectiveDate = undefined;
    validationRetryCount = 0;
  };
}
